<template lang="pug">
  .wrapper.i-wrap()
    .section-left()
    .section-mid(style="width:100%")
      .container.i-coming-soon-wrap(v-if="!loading")
          .sectionTitle
            p {{theTitle}}
          .flexBreak          
          p.pointer(v-if="savedState && page > 1" @click="lastPage()" style="margin-top:20px;") 上一頁
      transition(name="fade")
        .container(v-if="!loading && model == 'movie'")
          .i-coming-soon-wrap(v-if="mtes.length > 0")
            .i-coming-soon(v-for="mte in mtes")
              mtesCard(:mtes="mte")
      transition(name="fade")  
        .container(v-if="!loading && model == 'article'")
          .i-coming-soon-wrap(v-if="articles.length > 0")
            .article-list(v-if="articles.length > 0")
              .article-wrap(v-for="article in articles")
                ArticleCard(
                  :article="article"
                  :cms="false"
                )
        
        .i-coming-soon-wrap(v-else)
          p 沒有更多結果
        .container.i-config-soon-wrap(v-else style="text-align:center; justify-content:center")
          Spinner
      InfiniteLoading( :identifier="infiniteId" @infinite="infiniteHandler")
        div( slot="spinner") 
          Spinner
        div( slot="no-more") 沒有更多結果
        div( slot="no-results") 沒有更多結果
    .section-right

</template>

<script>
import ArticleCard from '@/components/ArticleCard';
import mtesCard from '@/components/mtesCard'
import Spinner from 'vue-simple-spinner'
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'Browse',
  components: {
    ArticleCard,
    Spinner,
    mtesCard,
    InfiniteLoading,
  },
  props:{
    model:String,
    listType:String,
  },
  data(){
    return{
      loading:true,
      mtes:[],
      articles:[],
      theTitle:'',
      page:0,
      endOfPosts:false,
      infiniteId: +new Date(),
      savedState:false,
    }
  },
  metaInfo() {
    var x = this.loading;
    if(!x){
      if(this.model == 'movie'){
        if(this.listType == 'inTheaters') this.theTitle = '現正熱映' 
        if(this.listType == 'comingSoon') this.theTitle = '即將上映'          
      }      
      if(this.model == 'article'){
        if(this.listType == 'latest') this.theTitle = '焦點新聞'  
      }
    }
    return{
      title: !x ? this.theTitle : 'Loading...',
      titleTemplate: '%s - 臭豆腐 - 電影∣影集∣評分∣評論'
    }
  },
  methods:{
    async init(){
      if(this.$route.query.page){
        this.page = parseInt(this.$route.query.page)
        if(this.page > 0) this.savedState = true;
      }else{
        this.page = 0;
      }
      this.loading = false;
    },
    async lastPage(){
      this.loading = true;
      this.page = this.page - 2;
      this.mtes = [];
      this.changeType()
      this.loading = false;
    },
    changeType() {
      this.infiniteId += 1;
    },
    async infiniteHandler($state){
      try{
        var {data} = await this.axios.post(`/api/browse/${this.model}/${this.listType}`,{page: this.page})
        if(data.error) throw data.errMsg
        
        if(this.model == 'movie'){
          if(data.length > 0){
            this.mtes.push(...data)
            history.replaceState(null, 'hi', `/browse/${this.model}/${this.listType}?page=${this.page}`)
            this.page += 1;
            $state.loaded();
          }else{
            $state.complete();
          }
        }
        if(this.model == 'article'){
          if(data.length > 0){
            this.articles.push(...data)
            history.replaceState(null, 'hi', `/browse/${this.model}/${this.listType}?page=${this.page}`)
            this.page += 1;
            $state.loaded();
          }else{
            $state.complete();
          }
        }
      }catch(err){
        this.flash(err,'error')
        $state.complete()
      }
    },    
  },
 
  async created(){
    this.init();      
  }
}
</script>
<style lang="scss" scoped>
  .genre{
    border:solid 1px lightgrey;
    padding:5px 10px;
    border-radius:5px;
    margin-right:10px;      
    margin:10px;
  }
  .genre.active{
    background: lightgrey;
  }
  .genre-list-wrap{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .i-coming-soon-wrap{
    width:100%;
    flex-wrap:wrap;
    justify-content: center;
    display:flex;
  }
  .i-coming-soon.active{
    height:300px;
  }
  .i-coming-soon{
    margin:10px;
    max-width:300px;
    flex-grow:0;
    // flex-basis:300px;
  }
  .article-list{
    display:flex;
    flex-wrap:wrap;
  }
  .article-wrap{
    width:calc(25% - 14px);
    margin:7px;
  }
  @media screen and (max-width:1024px) {
    
    .article-list .article-wrap{
      width:23%;
      margin:1%;
    }
  }
  @media screen and (max-width:768px) {
    
    .article-list .article-wrap{
      width:30%;
      margin:1%;
    }
  }
  @media screen and (max-width:480px) {
    .article-list .article-wrap{
      width:48%;
      margin:1%;
    }
  }

</style>
